@import 'src/master';

.sideBarWrapper {
	height: 100%;

	.sideBar {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		height: 100%;
		background-color: $turkoosi;

		.drawerLogo {
			width: 200px;
			align-self: center;
			margin-top: 5%;
		}
		.logoWrapper {
			display: flex;
			flex-direction: column;

			.neuvokasLogo {
				align-self: center;
				width: 200px;
				margin-bottom: 5%;
			}
		}

		.navigationList {
			background-color: $saavutettavaTurkoosi;
		}

		.navigationListDivider {
			background-color: #ffffff80;
		}
	}
	@media all and (max-width: 800px) {
		.sideBar {
			width: 70vw;
			.drawerLogo {
				width: 40%;
				align-self: center;
				margin-top: 5px;
				margin-bottom: 10px;
			}
			.logoWrapper {
				display: flex;
				flex-direction: column;
				.neuvokasLogo {
					align-self: center;
					width: 40%;
					margin-bottom: 30px;
				}
			}
		}
	}
}
