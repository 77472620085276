@import 'src/master';

.questionnaireWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $vaaleanharmaa;

	margin: 0 0 0 0;

	.sectionContainer {
		width: 100%;

		background-color: $color-background-main;
	}
	.sectionContainer:nth-of-type(n + 2) {
		margin-top: 20px;
	}
	.endQuestionnaireButtonWrapper {
		background-color: $turkoosi;
		width: 100%;
		padding: 40px 0 40px 0;
		display: flex;
		align-content: center;
		justify-content: center;
		.endQuestionnaireButton {
			height: 50px;
			width: 250px;
			font-size: 20px;
			background-color: $color-button-primary;
			&:hover,
			&:active {
				background-color: $color-button-primary;
			}
		}
	}
}

.questionnaireWrapperMobile {
	width: 100%;
	height: 100%;
	.react-swipeable-view-container {
		width: 100%;
		height: 100%;
		.questionWrapperMobile {
			width: 100%;
			height: 100%;
		}
	}
	.questionWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.ageAndNameContainer {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			text-align: left;
			padding: 20px 0 20px 0;
			width: 90%;
			margin-top: 20px;
			align-items: center;

			.textfieldContainer {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				.text-field-name {
					width: 70%;
				}

				.text-field-age {
					width: 10%;
				}
			}
			.neuvokasQuestion {
				color: $saavutettavaTurkoosi !important;
				font-weight: bold !important;
			}

			.textInput:after {
				border-bottom-color: $turkoosi !important;
			}
		}

		.adultNamesContainer {
			margin-top: 20px;
			width: 90%;
		}
	}
	.panelWrapper {
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.panel {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 10% 5% 15% 5%;
			text-align: left;
			align-items: center;
			margin-top: 20px;

			width: 80%;

			.endQuestionnaireButton {
				margin-top: 40px;
				width: 40%;
			}
		}
		.buttonContainer {
			margin-top: 20px;
			display: flex;
			justify-content: space-evenly;
			width: 100%;
		}
	}
	.buttonContainer {
		margin-top: 20px;
		display: flex;
		justify-content: space-evenly;
		width: 100%;
	}
}
