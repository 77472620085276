@import 'src/master';

.cardSelection {
	padding: 80px 40px 40px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.cardSelectionImage {
		width: 75%;

		margin-bottom: 50px;
	}

	.neuvokasText {
		width: 70%;
		text-align: left;
		margin-bottom: 40px;
	}

	.buttonWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		button {
			width: 250px;
			margin: 10px;
		}
	}
}

@media all and (max-width: 800px) {
	.cardSelection {
		background-color: white;
		padding: 0px 0 20px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.cardSelectionImage {
			width: 100%;
			margin-bottom: 20px;
		}

		.neuvokasText {
			width: 90%;
			text-align: left;
			margin-bottom: 20px;
		}

		.buttonWrapper {
			min-height: 200px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;

			button {
				min-height: 48px;
				width: 250px;
				margin: 10px;
				height: 50px;
			}
		}
	}
}
