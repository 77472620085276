@import 'src/master';

.instructions {
	.neuvokasText {
		text-align: left;

		.colorContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.checkmark {
			display: inline-flex;
			position: relative;
			top: 8px;
			height: 30px;
			width: 30px;
			border-radius: 50%;
			margin-right: 10px;
			&.green {
				background-color: $color-green;
			}
			&.yellow {
				background-color: $color-yellow;
			}
			&.red {
				background-color: $color-red;
			}
		}
	}
	.colorContainer {
		margin: 30px 0 30px 0;
		line-height: 30px;
	}
}
