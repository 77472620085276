$turkoosi: #00a5c8;
$vaaleansininen: #84d2e2;
$puuteri: #fee4d2;
$minttu: #7fd5c5;
$metsanvihrea: #00747a;
$esikonkeltainen: #eade29;
$vaaleanruskea: #cdb6a7;
$vaaleanharmaa: #ededec;
$punainen: #cc0000;

$saavutettavaTurkoosi: darken($turkoosi, 10);

$color-background-main: $turkoosi;
$color-background-page: $vaaleanharmaa;
$color-top-bar: white;
$color-background-bottom-bar: $puuteri;
$color-background-paper: white;

$color-basic-text: #333;

// buttons
$color-button-primary: darken($turkoosi, 10);
$color-button-hover: lighten($turkoosi, 10);
$color-button-text: white;

$color-top-bar-icons: darken($turkoosi, 10);
$color-top-bar-font: darken($turkoosi, 10);

// traffic lights
$color-green: #7bbb7b;
$color-yellow: #fbce7b;
$color-red: #fb7b7b;

$color-bottom-bar-text: $punainen;
