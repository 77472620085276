@import 'src/master';

.main-view {
	height: 100%;
	width: 100%;
	margin: 0;

	.topBarWrapper {
		width: 100%;
		height: 60px;
	}

	.flexWrapper {
		display: flex;
		.questionnaireFlexWrapper {
			order: 2;
			flex: 2 0px;
			padding-top: 40px;
			padding-bottom: 40px;
			background-color: $color-background-page;
		}
		.leftSideWrapper {
			order: 1;
			flex: 1;
			background-color: $color-background-page;
		}
		.rightSideWrapper {
			order: 3;
			flex: 1;
			justify-content: flex-start;
			padding-left: 20px;
			padding-top: 40px;
			background-color: $color-background-page;
		}
	}

	@media all and (max-width: 1200px) {
		.leftSideWrapper,
		.rightSideWrapper {
			display: none;
		}
		.questionnaireFlexWrapper {
			padding: 20px !important;
		}
		.questionnaireWrapWrapper {
			width: 100%;
			height: calc(100% - 60px);
			margin-top: 0;
			padding-top: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 1;
		}
	}

	@media all and (max-width: 800px) {
		.questionnaireWrapWrapper {
			width: 100%;
			height: calc(100% - 60px);
			margin-top: 0;
			padding-top: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			flex: 1;
		}
	}

	background-color: $color-background-page;

	.frontpage-image {
		max-width: 400px;
	}
	.frontpage-headline {
		margin-top: 50px;
		font-size: 1.5em;
		color: white;
		font-weight: 500;
	}

	.frontpage-button {
		margin-top: 50px;
		height: 50px;
		width: 250px;
		font-size: 20px;
		background-color: $color-button-primary;
		&:hover,
		&:active {
			background-color: $color-button-primary;
		}
	}
}
