@import 'src/master';

.choiceRadio {
	.choiceRadioForm {
		display: flex;
		flex-direction: row;
		width: 100%;
		/* Customize the label (the container) */
		.container {
			height: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-size: 22px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			input {
				position: absolute;
				opacity: 0;
				height: 0;
				width: 0;
			}
			/* Create a custom radio button */
			.checkmark {
				display: flex;

				height: 30px;
				width: 30px;
				border-radius: 50%;
				&.green {
					background-color: $color-green;
				}
				&.yellow {
					background-color: $color-yellow;
				}
				&.red {
					background-color: $color-red;
				}
				.checkedMark {
					display: block;
					height: 30px;
					width: 30px;
				}
				.white {
					color: white;
				}

				align-items: center;
				justify-content: space-around;
			}

			@media all and (max-width: 800px) {
				.checkmark {
					height: 50px;
					width: 50px;
					border-radius: 50%;
					&.green {
						background-color: $color-green;
					}
					&.yellow {
						background-color: $color-yellow;
					}
					&.red {
						background-color: $color-red;
					}
					.checkedMark {
						display: block;
						height: 50px;
						width: 50px;
					}
				}
			}

			input + .checkmark {
				cursor: pointer;
			}

			input:hover + .checkmark {
				&.green {
					background-color: lighten($color-green, 10);
				}
				&.yellow {
					background-color: lighten($color-yellow, 10);
				}
				&.red {
					background-color: lighten($color-red, 10);
				}
			}

			input:checked + .checkmark {
				.checkedMark {
					height: 32px;
					width: 43px;
					background-image: url(check.png);
					background-size: 40px;
					background-position: center bottom;
					background-repeat: no-repeat;
				}
			}

			@media all and (max-width: 800px) {
				input:checked + .checkmark {
					.checkedMark {
						height: 50px;
						width: 60px;
						background-image: url(check.png);
						background-size: 60px;
						background-position: center bottom;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
}

@media all and (max-width: 800px) {
	.choiceRadio {
		.choiceRadioForm.verticalButtons {
			flex-direction: column !important;
		}
	}
}
