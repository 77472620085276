@import 'src/master';
.extraInfoDialog {
	min-width: 200px;
	padding: 0;
	margin: 0;
	#simple-dialog-title {
		padding-top: 0;
	}
	.closeButton {
		display: flex;
		align-self: flex-end;
		margin: 10px;
		transform: scale(1.2);
		color: $turkoosi;
	}
	.infoWrapper {
		display: flex;
		padding: 20px 40px 40px 40px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.title {
			font-family: Gerbera-medium;
			margin-bottom: 10px;
		}
		.startImage {
			width: 200px;
			height: auto;
		}
		.infoTextWrapper {
			margin-top: 30px;
		}

		.infoLinkWrapper {
			margin-top: 20px;
		}
	}
}
@media all and (max-width: 800px) {
	.extraInfoDialog {
		min-width: unset;
		.dialogPaperWrapper {
			min-width: 60%;
			padding: 0;
			margin: 5%;
			overflow-y: scroll;
		}
		.infoWrapper {
			display: flex;

			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 10px;

			.startImage {
				max-height: 40%;
				max-width: 40%;
			}
			.infoTextWrapper {
				margin-top: 5%;
			}

			.infoLinkWrapper {
				margin-top: 5%;
			}
		}
	}
}
