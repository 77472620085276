@import 'src/master';

.nameSelector {
	display: flex;
	flex-direction: column;
	justify-content: center;

	.nameSelectorContainer {
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		padding: 20px;
		text-align: left;
		.introText {
			text-align: left;
			padding-bottom: 10px;
			font-family: Gerbera-light;
		}
		textarea {
			margin-top: 10px;

			min-height: 120px;
		}

		.nameSelectorTextContainer {
			align-self: flex-start;
			margin-bottom: 15px;

			.nameSelectorTextWrapper {
				width: 300px;
				:after {
					border-bottom-color: $turkoosi !important;
				}
			}
		}
	}

	.nameSelectorAddIcon {
		background-color: $turkoosi;
		&:hover {
			background-color: $saavutettavaTurkoosi;
		}
	}
	.nameList {
		display: flex;
		flex-direction: row;
		height: 70px;
	}
	.nameListItem {
		max-width: 300px;
		background-color: lighten(#8c9925, 55);
		margin-right: 10px;
		&:hover {
			background-color: lighten(#8c9925, 50);
		}
	}
	.openQuestionTextWrapper {
		width: 200px;
		border-color: $turkoosi !important;
		:focus {
			border-color: $turkoosi !important;
		}
	}
}

@media all and (max-width: 800px) {
	.nameSelector {
		.nameSelectorContainer {
			background-color: white;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			padding: 10px;
			width: calc(100% - 20px);
			text-align: left;
			.introText {
				text-align: left;
				padding-bottom: 10px;
				font-family: Gerbera-light;
			}
			textarea {
				margin-top: 10px;

				min-height: 120px;
			}

			.nameSelectorTextContainer {
				display: flex;
				flex-direction: row;
				align-self: flex-start;
				justify-content: space-between;

				.nameSelectorTextWrapper {
					:after {
						border-bottom-color: $turkoosi !important;
					}
					.neuvokasQuestion {
						font-size: 1em !important;
					}
					.nameSelectorInput {
					}
				}
			}

			.nameList {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
			}
			.nameListItem {
				width: 100%;
			}
		}
	}
}
