@import 'src/master';

html {
	height: 100%;
	width: 100%;
	background-color: $color-top-bar;
}

body {
	margin: 0;
	padding: 0;
	height: 100%;

	font-family: Gerbera-light;
	font-weight: normal;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#root {
	height: 100%;
	width: 100%;
}

input,
textarea,
button,
select,
label,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.neuvokasPaper {
	margin-bottom: auto;
	background-color: $color-background-paper;

	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);
	-moz-border-radius-topleft: 10px;
	-moz-border-radius-topright: 10px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;

	-webkit-border-top-left-radius: 10px;
	-webkit-border-top-right-radius: 10px;
	-webkit-border-bottom-right-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.neuvokasPaperMobile {
	background-color: $color-background-paper;
	height: 100%;
	width: 100%;

	text-align: left;

	font-size: 1.2em !important;
	font-family: Gerbera-medium !important;
	font-weight: normal !important;
	font-style: normal !important;

	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.12);

	-moz-border-radius-topleft: 10px;
	-moz-border-radius-topright: 10px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;

	-webkit-border-top-left-radius: 10px;
	-webkit-border-top-right-radius: 10px;
	-webkit-border-bottom-right-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.neuvokasSplashButton {
	text-decoration: none !important;
	text-shadow: none !important;
	border-radius: 5px !important;
	font-size: 1.5em !important;
	font-family: Gerbera-medium !important;
	font-weight: normal !important;
	font-style: normal !important;
	text-transform: none !important;
	box-shadow: none !important;

	color: $color-button-text !important;
	border: solid 2px white !important;
	background-color: $color-button-primary !important;
	&:hover,
	&:active {
		background-color: lighten($color-button-primary, 5) !important;
	}
}

.neuvokasButton {
	text-decoration: none !important;
	text-shadow: none !important;
	border-radius: 5px !important;
	font-size: 1.2em !important;
	font-family: Gerbera-medium !important;
	font-weight: normal !important;
	font-style: normal !important;
	text-transform: none !important;
	box-shadow: none !important;
	padding: 8px 12px 8px 12px !important;

	color: $color-button-text !important;
	border: solid 2px white !important;
	background-color: $color-button-primary !important;
	&:hover,
	&:active {
		background-color: lighten($color-button-primary, 5) !important;
	}
}

.neuvokasButtonOnWhite {
	box-shadow: none !important;

	text-decoration: none !important;
	text-shadow: none !important;
	border-radius: 5px !important;

	font-size: 1.2em !important;
	line-height: 30px !important;
	font-family: Gerbera-medium !important;
	font-weight: normal !important;
	font-style: normal !important;
	text-transform: none !important;

	color: $turkoosi !important;
	border: solid 2px $turkoosi !important;
	background-color: white !important;
	&:hover,
	&:active {
		background-color: lighten($color-button-primary, 5) !important;
	}
}

.neuvokasTitle {
	font-size: 32pt !important;
	font-family: Gerbera-bold !important;
	font-weight: normal !important;
	font-style: normal !important;
}

.neuvokasTopBarTitle {
	font-size: 1.2em !important;
	font-family: Gerbera-bold !important;
	font-weight: normal !important;
	font-style: normal !important;
}

.neuvokasText {
	font-size: 1.1em !important;
	font-weight: normal !important;
	font-family: Gerbera-light !important;
	color: $color-basic-text !important;
}

.languageItem {
	color: $color-top-bar-icons !important;
	font-size: 1.1em !important;
	font-weight: normal !important;
	font-family: Gerbera-light !important;
}

.menuItem {
	color: white !important;
	font-size: 1.1em !important;
	font-weight: normal !important;
	font-family: Gerbera-medium !important;
}

.neuvokasButtonWrapper {
	padding: 40px 0 40px 0;
	display: flex;
	width: 100%;
	justify-content: center;

	button {
		margin-left: 10px;
	}
}

.neuvokasQuestion {
	font-size: 1.3em !important;
	font-weight: normal !important;
	font-family: Gerbera-light !important;
	color: $color-basic-text !important;
}

@media all and (max-width: 800px) {
	.neuvokasQuestion {
		font-size: 1.3em !important;
	}
}
