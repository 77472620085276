@import 'src/master';

.feedbackView {
	width: 100%;

	.feedbackWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		padding-top: 60px;

		.ageAndName {
			width: 70%;
			padding: 20px;
			display: flex;
			justify-content: space-evenly;
			.boldedText {
				font-weight: bold;
			}
		}

		.descriptionWrapper {
			padding: 20px;
			width: 70%;
		}
		.pieChartContainer {
			display: flex;
			height: 100%;
			flex-direction: column;
			align-items: center;
			max-width: 70%;
		}

		.pieChartWrapper {
			margin-top: 40px;
			width: 500px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
		}

		.multiplePieChartContainer {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			padding-top: 30px;
			flex-direction: row;
			align-items: center;

			.adultNameSwipeText {
				margin-bottom: 10px;
				font-weight: bold;
			}

			.pieChartAndNameContainer {
				display: flex;
				height: 100%;
				flex-direction: column;
				align-items: center;
			}
		}

		.neuvokasButtonWrapper {
			margin: 40px;
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			align-content: center;

			.backButton {
				background-color: darken(#c6d54f, 20) !important;
				height: 53px;
				line-height: 37px;
				text-align: center;
				.arrowBackIcon {
					transform: scale(0.9);
				}
			}
			.toCardSelectionButton {
				width: 200px;
			}
		}
		.answerList {
			display: flex;
			flex-direction: column;
			list-style: none;
			padding: 0;
			align-items: center;
			justify-content: center;
			align-content: center;
			padding: 0 10% 0 10%;

			.answerCountListItem {
				display: flex;
				justify-content: space-between;
				align-items: center;
				align-content: space-between;
				text-align: left;
				width: 100%;
				.ball {
					margin-right: 20px;
					align-self: flex-start;
				}

				.answerButton {
					margin-left: 20px;
					&.green {
						border-color: $color-green;
					}
					&.yellow {
						border-color: $color-yellow;
					}
					&.red {
						border-color: $color-red;
					}
				}
			}
		}
		.listAndAnswerWrapper {
			padding: 0 10px 0 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.answerButtonList {
				display: flex;
				flex-direction: column;
				list-style: none;
				padding: 0;
				align-items: center;
				justify-content: center;
				align-content: center;
				width: 100%;
				max-width: 500px;
				margin: 0;

				.listItemButton {
					padding: 0;
					width: 100%;
					margin: 10px 0 0 0;

					.ball {
						align-self: flex-start;
						margin-right: 10px;
					}

					.answerButton {
						width: 100%;
						&.green {
							border-color: $color-green;
						}
						&.yellow {
							border-color: $color-yellow;
						}
						&.red {
							border-color: $color-red;
						}
					}
				}
			}
			.openAnswerList {
				margin-top: 30px;
				margin-bottom: 20px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
			}
		}

		.questionItem {
			margin-top: 10px;
			font-weight: bold;
		}
		.questionAnswer {
			margin-top: 5px;
			padding-left: 20px;
		}
	}
	.neuvokasTopBarTitle {
		padding: 40px 0 20px 0;
	}
	.questionList {
		display: flex;
		flex-direction: column;
		.listButtonWrapper {
			padding-bottom: 40px;
			.backButton {
				margin-top: 50px;
				height: 50px;
				width: 250px;
				font-size: 20px;
				color: black;
				border: solid 2px white;
				background-color: $color-button-primary;
				&:hover,
				&:active {
					background-color: $color-button-primary;
				}
			}
		}

		.answerList {
			padding: 0;
		}
		li {
			display: flex;
			flex-direction: column;
			padding: 5px 20px 5px 20px;

			.liContainer {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-content: space-between;
				justify-content: space-between;
				align-items: center;
				.infoIcon {
					color: $turkoosi;
					font-size: 35px;
				}
			}
			.followUpContainer {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: center;
				.questionText {
					margin-right: 20px;
				}
			}
		}

		.color-1:nth-child(odd) {
			background-color: lighten($color-green, 20);
		}
		.color-1:nth-child(even) {
			background-color: lighten($color-green, 35);
		}

		.color-2:nth-child(odd) {
			background-color: lighten($color-yellow, 20);
		}
		.color-2:nth-child(even) {
			background-color: lighten($color-yellow, 25);
		}

		.color-3:nth-child(odd) {
			background-color: lighten($color-red, 20);
		}
		.color-3:nth-child(even) {
			background-color: lighten($color-red, 25);
		}
	}
}

@media all and (max-width: 800px) {
	.feedbackView {
		.feedbackWrapper {
			background-color: white;
			display: flex;

			flex-direction: column;
			padding: 0;
			padding-top: 0;

			.multiplePieChartContainer {
				flex-direction: column;
			}
			.descriptionWrapper {
				width: unset;
				.neuvokasTopBarTitle {
					padding: 0;
				}
			}
			.pieChartContainer {
				.listAndAnswerWrapper {
					.openAnswerList {
						margin-top: 0px;
						margin-bottom: 20px;
						.neuvokasTopBarTitle {
							padding-top: 10px;
						}
					}
				}
				.listAndAnswerWrapper {
					display: none;
				}

				.pieChartWrapper {
					width: 90%;

					margin: 0;
					.openAnswerList {
						margin-top: 0px;
						margin-bottom: 20px;
					}
				}
			}

			.answerList {
				width: 100%;
				padding: 0;
			}

			.neuvokasButtonWrapper {
				margin: 0px;
				display: flex;

				padding: 0;
				margin: 0 0 20px 0;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				button {
					margin: 0;
					margin-top: 10px;
				}
			}
			.answerList {
				display: flex;
				flex-direction: column;
				list-style: none;
				padding: 0;
				align-items: center;
				justify-content: center;
				align-content: center;
				padding: 0 0% 0 0%;

				.answerCountListItem {
					display: flex;
					justify-content: space-between;
					align-items: center;
					align-content: space-between;
					text-align: left;

					.ball {
						width: 40px;
						height: 40px;
						margin-right: 5px;
						align-self: flex-start;
					}

					.answerButton {
						margin-left: 10px;
						&.green {
							border-color: $color-green;
						}
						&.yellow {
							border-color: $color-yellow;
						}
						&.red {
							border-color: $color-red;
						}
					}
				}
			}
		}
	}
}

#printPdf {
	position: fixed;

	bottom: -4000px;

	width: 210mm;
	.startImage {
		width: auto;
		height: 50mm;
		align-self: center;
	}
	.fullHeadline {
		font-family: gerbera-medium;
		font-size: 8mm;
		margin: 5mm;
	}
	.ageAndName {
		margin: 1mm 5mm 3.5mm 5mm;
		font-size: 5mm;
		display: flex;
		justify-content: space-around;
	}
	.feedbackWrapper {
		width: 100%;
		.printAnswerWrapper {
			width: 100%;
			margin: 5mm 0 5mm 0;
		}
	}
	.answerList {
		margin: 0 5mm 5mm 5mm;
	}

	.listWrapper {
		.colorText {
			height: 11mm;
			font-family: Gerbera-medium;
			font-size: 6mm;
			padding: 0;
			margin-top: 4mm;
		}
	}

	li {
		padding: 1mm;
	}
	.followUpContainer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		.questionText {
			margin-right: 20px;
		}
	}

	.openAnswerWrapper {
		display: flex;

		flex-direction: column;

		.openAnswerList {
			display: flex;
			justify-items: flex-start;
			align-items: flex-start;
			margin: 0 5mm 0 5mm;
		}
		.openAnswerTextWrapper {
			display: flex;
			flex-direction: column;
			margin: 2mm 5mm 2mm 5mm;

			.questionItem {
				text-align: left !important;
			}
			.questionAnswer {
				text-align: left !important;
			}
		}
	}
	.logoWrapper {
		display: flex;

		justify-content: space-between;
		padding: 10mm 5mm 0 5mm;
		.logoLeft {
			width: 40mm;
			height: 15mm;
		}
		.logoRight {
			width: 35mm;
			height: 15mm;
		}
	}
}
