@import 'src/master';

.topBar {
	width: 100%;
	height: 60px;
	z-index: 999;
	position: fixed;

	position: fixed;
	top: 0;
	display: flex;
	align-items: center;
	background-color: $color-top-bar;

	color: $color-top-bar-font;
	justify-content: space-between;

	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);

	-webkit-transition: background-color 300ms linear;
	-moz-transition: background-color 300ms linear;
	-o-transition: background-color 300ms linear;
	-ms-transition: background-color 300ms linear;
	transition: background-color 300ms linear;

	&.topBarColorId1 {
		background-color: $color-green;
		color: darken($saavutettavaTurkoosi, 20);
	}

	&.topBarColorId2 {
		background-color: $color-yellow;
		color: darken($saavutettavaTurkoosi, 20);
	}

	&.topBarColorId3 {
		background-color: $color-red;
		color: darken($saavutettavaTurkoosi, 20);
	}

	.topBarButton,
	.languageIcon {
		font-weight: 600;
		color: $color-top-bar-icons;
		transform: scale(1.4);

		&.topBarColorId1,
		&.topBarColorId2,
		&.topBarColorId3 {
			color: darken($saavutettavaTurkoosi, 20);
		}
	}

	.neuvokasTopBarTitle {
		font-size: 1.2em;
		font-weight: 550;
		text-align: center;
		align-self: center;
	}

	.buttonWrapper {
		width: 80px;
	}
}
