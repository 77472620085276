@import 'src/master';

.sectionWrapper {
	width: 100%;
	margin-top: 20px;
	padding-bottom: 20px;

	background-color: $color-background-main;

	.sectionTitle {
		font-size: 1.4em;
		font-family: Gerbera-bold !important;
		color: darken($saavutettavaTurkoosi, 20);
	}

	.adultNameWrapper {
		display: flex;
		justify-content: flex-end;
		font-weight: bold;
		padding: 12px 30px 0px 30px;
		.nameItem {
			overflow-x: hidden;
			padding: 0 10px 0 10px;
			width: 110px;
		}

		.nameItem:nth-last-child(3) {
		}
		.nameItem:nth-last-child(2) {
		}
		.nameItem:nth-last-child(1) {
		}
	}

	.questionWrapper {
		padding: 12px 30px 12px 30px;
		.ageAndNameContainer {
			background-color: white;
			padding: 0px 20px 20px 20px;
			display: flex;
			justify-content: space-evenly;
			-moz-border-radius-topleft: 10px;
			-moz-border-radius-topright: 10px;
			-moz-border-radius-bottomright: 10px;
			-moz-border-radius-bottomleft: 10px;
			-webkit-border-top-left-radius: 10px;
			-webkit-border-top-right-radius: 10px;
			-webkit-border-bottom-right-radius: 10px;
			-webkit-border-bottom-left-radius: 10px;
			box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
				0px 2px 1px -1px rgba(0, 0, 0, 0.12);

			.neuvokasQuestion {
				color: $saavutettavaTurkoosi !important;
				font-weight: bold !important;
			}

			.textInput:after {
				border-bottom-color: $turkoosi !important;
			}
		}
	}

	h1 {
		color: white;
		text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
	}
}
