@import 'src/master';

.infoDialog {
	min-width: 500px;
	max-width: 800px;
	margin: auto;
	.closeButton {
		display: flex;
		align-self: flex-end;
		margin: 10px;
		transform: scale(1.2);
		color: $turkoosi;
	}
	.instructionsWrapper {
		padding: 0 40px 40px 40px;
	}
}

@media all and (max-width: 800px) {
	.infoDialog {
		min-width: 200px;
		padding: 0;
		margin: 0;
		.dialogPaperWrapper {
			padding: 0;
			margin: 5%;
		}
		.instructionsWrapper {
			padding: 0px 20px 20px 20px;
		}
	}
}
